



























import {Component, Prop, Ref, Vue, Watch} from "vue-property-decorator";
import GenericDialog from "@/components/dialogs/GenericDialog.vue";

@Component({
  components: {GenericDialog}
})
export default class GenericDeleteDialog extends Vue {

  @Ref('userVerifyTextField') readonly userVerifyTextField!: any

  @Prop({required: true})
  readonly value!: boolean;

  @Prop({required: true})
  readonly dialogTitle!: string;

  @Prop({required: true})
  readonly loading!: boolean;

  @Prop({required: false})
  readonly verify?: string;

  userVerifyInput = '';
  rules = {
    required: (value: string) => !!value || 'Required.',
    check: (value: string) => value === this.verify || 'Does not match.'
  };

  onDelete() {
    if (this.verify && this.verify !== this.userVerifyInput) {
      return;
    }
    this.$emit('delete');
  }

  @Watch('value')
  async watchQuery(value: boolean) {
    if (value) {
      this.userVerifyInput = '';
      this.$nextTick(() => {
        this.userVerifyTextField.reset();
      });
    }
  }
}
