






















import {Component, Prop, Vue} from "vue-property-decorator";
import GenericDialog from "@/components/dialogs/GenericDialog.vue";
import {showSnackbar} from "@/utils";

@Component({
  components: {GenericDialog}
})
export default class CreateUserDialog extends Vue {

  @Prop({required: true})
  readonly value!: boolean;

  @Prop({required: true})
  readonly loading!: boolean;

  name = '';
  password = '';

  reset() {
    this.name = '';
    this.password = '';
  }

  async submit() {
    if (!this.name) {
      showSnackbar('Missing Name');
      return;
    }

    if (!this.password) {
      showSnackbar('Missing Password');
      return;
    }

    await this.$emit('submit', {
      name: this.name,
      password: this.password,
    });
  }
}
