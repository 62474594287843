











import {Component, Prop, Vue} from "vue-property-decorator";
import GenericDeleteDialog from "@/components/dialogs/GenericDeleteDialog.vue";

@Component({
  components: {GenericDeleteDialog}
})
export default class DeleteAccountDialog extends Vue {

  @Prop({required: true})
  readonly value!: boolean;

  @Prop({required: true})
  readonly loading!: boolean;

  @Prop({required: true})
  readonly categoryName!: string;

  @Prop({required: true})
  readonly accountName!: string;
}
