import {
    decrypt,
    decryptAccount,
    decryptUser,
    encrypt,
    encryptOrNull,
    getExternalPassword,
    getInternalPassword
} from "@/crypto";
import {
    AccountDto,
    AccountEncryptedDto,
    AccountNoId,
    AccountsOfCategoryDto,
    CategoryDto,
    IpDto,
    LogDto,
    UserDto,
    UserLocale
} from "@/model";

const API_URL = "/api";

let masterExternalPassword: string | null = null;
let masterInternalPassword: string | null = null;
let jwt: string | null = null;
let handle401: Function | null = null;

function resetCredentials() {
    masterExternalPassword = null;
    masterInternalPassword = null;
    jwt = null;
}

interface RequestParameter {
    method: 'GET' | 'POST' | 'PUT' | 'DELETE';
    route: string;
    body?: object;
    params?: { [key: string]: any };
    no401Callback?: boolean;
    noException?: boolean;
}

async function makeRequest({ method, route, body, params, no401Callback, noException }: RequestParameter) {
    const url = new URL(API_URL+'/'+route, document.location.href);

    if (params) {
        // add query params
        for (const propName in params) {
            if (params[propName] === null || params[propName] === undefined) {
                delete params[propName];
            }
        }

        url.search = new URLSearchParams(params).toString();
    }

    const response = await fetch(url.toString(), {
        method,
        body: JSON.stringify(body),
        headers: { "Content-Type": "application/json", "Authorization": jwt! },
    });

    // handle 401
    if(!no401Callback && response.status === 401 && handle401) {
        resetCredentials();
        await handle401();
        return {};
    }

    // handle errors
    if(!noException && response.status >= 400) {
        throw response.status;
    }

    // return the result
    return JSON.parse((await response.text()) || "{}");
}

export function initAPI(h401: Function) {
    handle401 = h401;
    console.log("API initialized.");
}

interface LoginPayload {
    name: string;
    password: string;
}

export async function login(payload: LoginPayload): Promise<UserDto> {
    const response = await makeRequest({
        route: "login",
        method: "POST",
        body: {
            name: payload.name,
            password: getExternalPassword(payload.password)
        },
    });

    // safe to global
    jwt = response.token;
    masterExternalPassword = getExternalPassword(payload.password);
    masterInternalPassword = getInternalPassword(payload.password);

    return decryptUser(response.user as UserDto, masterExternalPassword!);
}

export function logout() {
    resetCredentials();
}

export async function getIP(): Promise<IpDto> {
    return await makeRequest({
        route: 'ip',
        method: 'GET'
    });
}

export async function getCategories(): Promise<Array<CategoryDto>> {
    const response = await makeRequest({
        route: 'categories',
        method: 'GET'
    });

    response.forEach((category: CategoryDto) => {
        category.name = decrypt(category.name, masterInternalPassword!);
    });

    return response.sort((a: CategoryDto, b: CategoryDto) => a.name.localeCompare(b.name));
}

interface CreateCategoryPayload {
    name: string;
}

export async function createCategory(payload: CreateCategoryPayload): Promise<CategoryDto> {
    return await makeRequest({
        route: 'categories',
        method: 'POST',
        body: { name: encrypt(payload.name, masterInternalPassword!) }
    });
}

interface UpdateCategoryPayload {
    id: number;
    name: string;
}

export async function updateCategory(payload: UpdateCategoryPayload) {
    return await makeRequest({
        route: 'categories',
        method: 'PUT',
        body: { id: payload.id, name: encrypt(payload.name, masterInternalPassword!) }
    });
}

interface DeleteCategoryPayload {
    categoryId: number;
}

export async function deleteCategory(payload: DeleteCategoryPayload) {
    return await makeRequest({
        route: 'categories',
        method: 'DELETE',
        params: { categoryId: payload.categoryId }
    });
}

export async function getAllAccounts(): Promise<Array<AccountDto>> {
    const response = await makeRequest({
        route: 'accounts',
        method: 'GET'
    });

    return response
        .map((account: AccountEncryptedDto) => decryptAccount(account, masterInternalPassword!))
        .sort((a: AccountDto, b: AccountDto) => a.data.name.localeCompare(b.data.name));
}

interface GetAccountsPayload {
    categoryId: number;
}

export async function getAccounts(payload: GetAccountsPayload): Promise<AccountsOfCategoryDto> {
    const response = await makeRequest({
        route: 'accounts/'+payload.categoryId,
        method: 'GET'
    });

    response.category.name = decrypt(response.category.name, masterInternalPassword!);
    const accounts = response.accounts
        .map((account: AccountEncryptedDto) => decryptAccount(account, masterInternalPassword!, response.category.name))
        .sort((a: AccountDto, b: AccountDto) => a.data.name.localeCompare(b.data.name));

    return {
        category: response.category,
        accounts
    };
}

type CreateAccountPayload = AccountNoId;

export async function createAccount(payload: CreateAccountPayload) {
    return await makeRequest({
        route: 'accounts',
        method: 'POST',
        body: {
            categoryId: payload.categoryId,
            data: encrypt(JSON.stringify(payload.data), masterInternalPassword!),
        }
    });
}

type UpdateAccountPayload = { id: number } & AccountNoId;

export async function updateAccount(payload: UpdateAccountPayload) {
    return await makeRequest({
        route: 'accounts',
        method: 'PUT',
        body: {
            id: payload.id,
            categoryId: payload.categoryId,
            data: encrypt(JSON.stringify(payload.data), masterInternalPassword!),
        }
    });
}

interface DeleteAccountPayload {
    accountId: number;
}

export async function deleteAccount(payload: DeleteAccountPayload) {
    return await makeRequest({
        route: 'accounts',
        method: 'DELETE',
        params: { accountId: payload.accountId }
    });
}

export async function getUserInfo(): Promise<UserDto> {
    const response = await makeRequest({
        route: 'settings/info',
        method: 'GET'
    }) as UserDto;

    return decryptUser(response, masterExternalPassword!);
}

interface UpdateMasterNamePayload {
    name: string;
}

export async function updateMasterName(payload: UpdateMasterNamePayload) {
    await makeRequest({
        route: 'settings/name',
        method: 'PUT',
        body: {
            name: payload.name
        }
    });
}

interface UpdateMasterPasswordPayload {
    oldPassword?: string;
    oldPasswordHash?: string; // prefers hash over plain
    newPassword: string;
}

type UpdateMasterPasswordAccount = { categoryId?: number } & AccountDto;

export async function updateMasterPassword(payload: UpdateMasterPasswordPayload) {

    const newExternalPassword = getExternalPassword(payload.newPassword);
    const newInternalPassword = getInternalPassword(payload.newPassword);

    // update categories
    const categories = await getCategories();
    categories.forEach((c: CategoryDto) => {
        c.name = encrypt(c.name, newInternalPassword);
    });

    // update accounts
    const accounts = await getAllAccounts();
    const accountsEncrypted = accounts.map((a: UpdateMasterPasswordAccount) => {
        return {
            id: a.id,
            categoryId: a.category.id,
            data: encrypt(JSON.stringify(a.data), newInternalPassword)
        }
    });

    await makeRequest({
        route: 'settings/password',
        method: 'PUT',
        body: {
            oldPassword: payload.oldPasswordHash || getExternalPassword(payload.oldPassword!),
            newPassword: newExternalPassword,
            categories,
            accounts: accountsEncrypted
        }
    });

    masterExternalPassword = newExternalPassword;
    masterInternalPassword = newInternalPassword;
}

export async function updateMasterPasswordSimple(newPassword: string) {
    await updateMasterPassword({
        oldPasswordHash: masterExternalPassword!,
        newPassword
    });
}

interface UpdateMasterLogoutTimeoutPayload {
    logoutTimeout: number;
}

export async function updateMasterLogoutTimeout(payload: UpdateMasterLogoutTimeoutPayload) {
    return await makeRequest({
        route: 'settings/logout-timeout',
        method: 'PUT',
        body: { logoutTimeout: payload.logoutTimeout }
    });
}

interface UpdateMasterLocalePayload {
    locale: UserLocale;
}

export async function updateMasterLocale(payload: UpdateMasterLocalePayload) {
    return await makeRequest({
        route: 'settings/locale',
        method: 'PUT',
        body: { locale: payload.locale }
    });
}

interface UpdateMasterTrustedIpsPayload {
    trustedIps: string[];
}

export async function updateMasterTrustedIps(payload: UpdateMasterTrustedIpsPayload) {
    return await makeRequest({
        route: 'settings/trusted-ips',
        method: 'PUT',
        body: { trustedIps: payload.trustedIps.map(ip => encrypt(ip, masterExternalPassword!)) }
    });
}

interface UpdateMasterEmailNoticePayload {
    emailNotice: string | null;
}

export async function updateMasterEmailNotice(payload: UpdateMasterEmailNoticePayload) {
    return await makeRequest({
        route: 'settings/email-notice',
        method: 'PUT',
        body: { emailNotice: encryptOrNull(payload.emailNotice, masterExternalPassword!) }
    });
}

export async function sendTestEmail(email: string) {
    return await makeRequest({
        route: 'settings/send-test-email',
        method: 'POST',
        params: { email }
    });
}

export async function getLogs(): Promise<Array<LogDto>> {
    return await makeRequest({
        route: 'logs',
        method: 'GET'
    });
}

export function decryptCurrentKey(data: string): string {
    return decrypt(data, masterInternalPassword!);
}

export async function getUsers(): Promise<Array<UserDto>> {
    return await makeRequest({
        route: 'users',
        method: 'GET'
    });
}

interface CreateUserPayload {
    name: string;
    password: string;
}

export async function createUser(payload: CreateUserPayload) {
    await makeRequest({
        route: 'users',
        method: 'POST',
        body: {
            name: payload.name,
            password: payload.password,
        },
    });
}

interface UpdateUserNamePayload {
    id: number;
    name: string;
}

export async function updateUserName(payload: UpdateUserNamePayload) {
    return await makeRequest({
        route: 'users',
        method: 'PUT',
        body: {
            id: payload.id,
            name: payload.name,
        },
    });
}

interface DeleteUserPayload {
    userId: number;
}

export async function deleteUser(payload: DeleteUserPayload) {
    return await makeRequest({
        route: 'users',
        method: 'DELETE',
        params: { userId: payload.userId }
    });
}
