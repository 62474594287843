

















import {getAllAccounts, updateAccount, deleteAccount} from "@/api";
import {Component, Prop, Ref, Vue, Watch} from "vue-property-decorator";
import {getValueCounts, showSnackbar} from "@/utils";
import AccountDialog from "@/components/dialogs/AccountDialog.vue";
import MainContainer from "@/components/MainContainer.vue";
import AccountTable from "@/components/AccountTable.vue";
import SearchField from "@/components/SearchField.vue";
import DeleteAccountDialog from "@/components/dialogs/DeleteAccountDialog.vue";
import {AccountDto, AccountNoId, CategoryDto, ValueCount} from "@/model";

@Component({
  components: {DeleteAccountDialog, SearchField, AccountTable, AccountDialog, MainContainer}
})
export default class AllAccounts extends Vue {

  @Ref('searchField') readonly searchField!: SearchField;
  @Ref('accountDialog') readonly accountDialog!: AccountDialog;

  @Prop({required: true})
  readonly categories!: Array<CategoryDto>;

  fetching = true;
  loading = false;
  accounts: Array<AccountDto> = [];
  emailList: Array<ValueCount> = [];
  phoneList: Array<ValueCount> = [];
  accountsFiltered: Array<AccountDto> = [];
  query = '';
  dialogAccount = false;
  dialogDeleteAccount = false;
  selectedAccount: AccountDto = { category: { id: -1, name: '' }, id: 0, data: { name: '', userName: null, email: null, phone: null, password: null, url: null, note: null, tags: [] } };

  async fetchData() {
    try {
      this.fetching = true;
      this.accounts = await getAllAccounts();
      this.emailList = getValueCounts(this.accounts, a => a.data.email);
      this.phoneList = getValueCounts(this.accounts, a => a.data.phone);
      if (this.query)
        this.accountsFiltered = this.filter(this.accounts, this.query);
      else
        this.accountsFiltered = [ ...this.accounts ];
    } catch (e) {
      console.error(e);
      showSnackbar('Error');
    } finally {
      this.fetching = false;
    }
  }

  filter(accounts: Array<AccountDto>, query: string) {
    const queryLowerCase = query.toLowerCase();
    return accounts.filter(a => a.data.name.toLowerCase().includes(queryLowerCase)
        || a.data.email?.toLowerCase()?.includes(queryLowerCase)
        || a.data.phone?.toLowerCase()?.includes(queryLowerCase)
        || a.data.tags.some(t => t.toLowerCase().includes(queryLowerCase)));
  }

  showUpdateAccountDialog(account: AccountDto) {
    this.selectedAccount = account;
    this.accountDialog.reset(account.category);
    this.accountDialog.load(account);
    this.dialogAccount = true;
  }

  showDeleteAccountDialog() {
    this.dialogAccount = false;
    this.dialogDeleteAccount = true;
  }

  async updateAccount(account: AccountNoId) {
    try {
      this.loading = true;
      await updateAccount({
        id: this.selectedAccount.id,
        ...account
      });
      this.dialogAccount = false;
      await this.fetchData();
    } catch (e) {
      showSnackbar('Error');
    } finally {
      this.loading = false;
    }
  }

  async deleteAccount() {
    try {
      this.loading = true;
      await deleteAccount({ accountId: this.selectedAccount.id });
      this.dialogDeleteAccount = false;
      await this.fetchData();
    } catch (e) {
      showSnackbar('Error');
    } finally {
      this.loading = false;
    }
  }

  @Watch('query')
  async watchQuery(value: string) {
    if (value)
      this.accountsFiltered = this.filter(this.accounts, value);
    else
      this.accountsFiltered = [ ...this.accounts ];
  }

  async mounted() {
    await this.fetchData();
    this.searchField.focus();
  }

}
