import CryptoJS from "crypto-js";
import {AccountData, AccountDto, AccountEncryptedDto, UserDto} from "@/model";

const cryptoConfig = {
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7
};

export function getExternalPassword(password: string): string {
    const concat = password + password;
    const hash = CryptoJS.SHA256(concat);
    return CryptoJS.enc.Base64.stringify(hash);
}

export function getInternalPassword(password: string): string {
    const concat = password + password + password;
    const hash = CryptoJS.SHA256(concat);
    return CryptoJS.enc.Base64.stringify(hash);
}

export function encrypt(data: string, password: string): string {

    // prepare data
    const salt = CryptoJS.lib.WordArray.random(16);
    const iv = CryptoJS.lib.WordArray.random(16);
    const message = CryptoJS.enc.Utf8.parse(data);

    // prepare key
    const key = CryptoJS.SHA256(password + salt.toString());

    const encrypted = CryptoJS.AES.encrypt(message, key, {
        iv: iv,
        ...cryptoConfig
    });

    const hex = salt.toString() + iv.toString() + encrypted.ciphertext.toString();
    return CryptoJS.enc.Base64.stringify(CryptoJS.enc.Hex.parse(hex));
}

export function encryptOrNull(data: string | null, password: string): string | null {
    if (data)
        return encrypt(data, password);
    else
        return null;
}

export function decrypt(data: string, password: string): string {

    // prepare data
    const raw = CryptoJS.enc.Base64.parse(data).toString();
    const salt = raw.slice(0, 32);
    const iv = CryptoJS.enc.Hex.parse(raw.slice(32, 64));
    const message = CryptoJS.enc.Hex.parse(raw.slice(64, raw.length));

    // prepare key
    const key = CryptoJS.SHA256(password + salt);

    // decrypt
    // @ts-ignore
    const decrypted = CryptoJS.AES.decrypt({ ciphertext: message }, key, {
        iv: iv,
        ...cryptoConfig
    });

    return CryptoJS.enc.Utf8.stringify(decrypted)
}

export function decryptOrNull(data: string | null, password: string): string | null {
    if (data)
        return decrypt(data, password);
    else
        return null;
}

export function decryptUser(user: UserDto, key: string): UserDto {
    user.emailNotice = decryptOrNull(user.emailNotice, key);
    user.trustedIps = user.trustedIps.map(ip => decrypt(ip, key));
    return user;
}

export function decryptAccount(account: AccountEncryptedDto, key: string, categoryName?: string): AccountDto {
    account.category.name = categoryName ?? decrypt(account.category.name, key);
    const data = JSON.parse(decrypt(account.data, key)) as AccountData;
    return {
        id: account.id,
        category: account.category,
        data
    };
}

const chars = ['0','1','2','3','4','5','6','7','8','9',
    'a','b','c','d','e','f','g','h','i','j','k','m','n','o','p','q','r','s','t','u','v','w','x','y','z',
    'A','B','C','D','E','F','G','H','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z'];

function randomUntil(max: number) {
    return Math.floor(Math.random() * (max+1));
}

export const generatePassword = () => {

    const length = 12;

    let pw = '';
    for(let i = 0; i < length; i++) {
        pw += chars[randomUntil(chars.length-1)];
    }

    return pw;
}