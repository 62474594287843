























import {Component, Prop, Vue} from "vue-property-decorator";
import GenericDialog from "@/components/dialogs/GenericDialog.vue";
import {showSnackbar} from "@/utils";
import {CategoryDto} from "@/model";

@Component({
  components: {GenericDialog}
})
export default class CategoryDialog extends Vue {

  @Prop({required: true})
  readonly value!: boolean;

  @Prop({required: true})
  readonly loading!: boolean;

  name = '';

  reset() {
    this.name = '';
  }

  load(category: CategoryDto) {
    this.name = category.name;
  }

  async submit() {
    if (!this.name) {
      showSnackbar('Missing Fields');
      return;
    }

    await this.$emit('submit', {
      name: this.name
    });
  }
}
