import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Login from "@/views/Login.vue";
import AllAccounts from "@/views/AllAccounts.vue";
import CreateCategory from "@/views/CreateCategory.vue";
import CategoryAccounts from "@/views/CategoryAccounts.vue";
import Logs from "@/views/Logs.vue";
import Settings from "@/views/Settings.vue";
import SetPassword from "@/views/SetPassword.vue";
import Users from "@/views/admin/Users.vue";

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Login',
    component: Login
  },
  {
    path: '/all',
    name: 'AllAccounts',
    component: AllAccounts
  },
  {
    path: '/category/new',
    name: 'CreateCategory',
    component: CreateCategory
  },
  {
    path: '/category/:categoryId',
    name: 'CategoryAccounts',
    component: CategoryAccounts
  },
  {
    path: '/settings',
    name: 'Settings',
    component: Settings
  },
  {
    path: '/logs',
    name: 'Logs',
    component: Logs
  },
  {
    path: '/set-password',
    name: 'SetPassword',
    component: SetPassword
  },
  {
    path: '/users',
    name: 'Users',
    component: Users
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
