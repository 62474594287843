





















import {Component, Prop, Vue} from "vue-property-decorator";

@Component
export default class GenericDialog extends Vue {

  @Prop({required: true})
  readonly value!: boolean;

  @Prop({required: true})
  readonly title!: string;

  @Prop({required: false, default: 500})
  readonly width!: number;

  @Prop({required: false, default: false})
  readonly persistent!: boolean;
}
