




















import {Component, Prop, Vue} from "vue-property-decorator";
import GenericDialog from "@/components/dialogs/GenericDialog.vue";
import {showSnackbar} from "@/utils";

@Component({
  components: {GenericDialog}
})
export default class SettingPasswordDialog extends Vue {

  @Prop({required: true})
  readonly value!: boolean;

  @Prop({required: true})
  readonly loading!: boolean;

  oldPassword = '';
  newPassword = '';
  newPasswordRepeat = '';

  reset() {
    this.oldPassword = '';
    this.newPassword = '';
    this.newPasswordRepeat = '';
  }

  async submit() {
    if (!this.oldPassword || !this.newPassword || !this.newPasswordRepeat)
      return;

    if (this.newPassword !== this.newPasswordRepeat) {
      showSnackbar('New passwords do not match.')
      return;
    }

    await this.$emit('submit', {
      oldPassword: this.oldPassword,
      newPassword: this.newPassword
    });
  }
}
