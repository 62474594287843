
























import {getCategories, initAPI, logout,} from "@/api";
import {Component, Vue} from "vue-property-decorator";
import {initSnackbar} from "@/utils";
import moment from "moment";
import Navigation from "@/components/Navigation.vue";
import {CategoryDto, UserDto} from "@/model";

@Component({
  components: {Navigation}
})
export default class App extends Vue {

  userInfo: UserDto | null = null; // not null if logged in
  timeout = 0; // timestamp for logout in millis since epoch
  seconds = 0; // remaining seconds representation of timeout
  timerId: number | null = null; // updating seconds and logout per tick
  categories: Array<CategoryDto> = [];

  // UI
  snackbar = false;
  snackbarText: string | null = null;

  tick() {
    this.seconds = Math.round((this.timeout - new Date().getTime()) / 1000);
    if (this.seconds <= 0) {
      logout();
      this.updateUserInfo(null);
      this.$router.push('/').catch(() => {});
      console.log('[' + moment().format('HH:mm:ss') + '] Logout (timeout)');
    }
  }

  async updateUserInfo(user: UserDto | null, setTimer?: boolean) {
    this.userInfo = user;
    if (this.userInfo) {
      if (setTimer) {
        console.log('[' + moment().format('HH:mm:ss') + '] Login');
        this.clearTimer();
        this.timeout = new Date().getTime() + (this.userInfo.logoutTimeout * 1000);
        this.seconds = this.userInfo.logoutTimeout;
        this.timerId = setInterval(this.tick, 1000);
        this.categories = await getCategories();
      }
    } else {
      this.categories = [];
      this.clearTimer();
    }
  }

  clearTimer() {
    if (this.timerId) {
      clearInterval(this.timerId);
      this.timerId = null;
    }
  }

  async updateCategories(categories: Array<CategoryDto>, selectedId?: number) {
    this.categories = categories;
    if (selectedId) {
      await this.$router.push('/category/' + selectedId).catch(() => {});
    } else {
      await this.$router.push('/all').catch(() => {});
    }
  }

  async handle401() {
    await this.updateUserInfo(null);
    await this.$router.push('/').catch(() => {});
  }

  showSnackbar(text: string) {
    this.snackbarText = text;
    this.snackbar = true;
  }

  async mounted() {
    initSnackbar(this.showSnackbar);
    initAPI(this.handle401);
  }
}
