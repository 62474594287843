var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',[_c('v-card-text',{staticClass:"pb-0"},[_c('v-data-table',{attrs:{"loading":_vm.fetching,"headers":(_vm.categoryColumn ? [{ text: 'Category', value: 'category.name' }] : []).concat( [{ text: 'Name', value: 'data.name' }],
              [{ text: 'Credentials', value: 'credentials', sortable: false }],
              [{ text: 'Actions', value: 'actions', sortable: false, width: 140 }]
          ),"items":_vm.accounts,"items-per-page":20,"footer-props":{
             itemsPerPageOptions: [ 20, 50, -1 ]
          },"loading-text":"Loading...","no-data-text":"No Accounts"},scopedSlots:_vm._u([{key:"item.category.name",fn:function(ref){
          var item = ref.item;
return [_c('a',{staticClass:"blue--text text--darken-3 text-decoration-underline",on:{"click":function($event){return _vm.$router.push('/category/'+item.category.id)}}},[_vm._v(_vm._s(item.category.name))])]}},{key:"item.data.name",fn:function(ref){
          var item = ref.item;
return [(item.data.url)?_c('a',{staticClass:"blue--text text--darken-3 text-decoration-underline",attrs:{"href":item.data.url,"target":"_blank","rel":"noreferrer"}},[_vm._v(" "+_vm._s(item.data.name)+" ")]):_c('span',[_vm._v(_vm._s(item.data.name))])]}},{key:"item.credentials",fn:function(ref){
          var item = ref.item;
return [(item.data.userName)?_c('v-chip',{staticClass:"ma-1",on:{"click":function($event){return _vm.copy(item.data.userName, 'User copied!')}}},[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v("mdi-account")]),_vm._v(" "+_vm._s(item.data.userName)+" ")],1):_vm._e(),(item.data.email)?_c('v-chip',{staticClass:"ma-1",on:{"click":function($event){return _vm.copy(item.data.email, 'Email copied!')}}},[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v("mdi-email")]),_vm._v(" "+_vm._s(item.data.email)+" ")],1):_vm._e(),(item.data.phone)?_c('v-chip',{staticClass:"ma-1",on:{"click":function($event){return _vm.copy(item.data.phone, 'Phone Number copied!')}}},[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v("mdi-phone")]),_vm._v(" "+_vm._s(item.data.phone)+" ")],1):_vm._e(),(item.data.password)?_c('v-chip',{staticClass:"ma-1",attrs:{"color":"blue-grey lighten-4"},on:{"click":function($event){return _vm.copy(item.data.password, 'Password copied!')}}},[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v("mdi-key")]),_vm._v(" "+_vm._s(_vm.showPassword(item) ? item.data.password : '●●●●●●')+" ")],1):_vm._e(),(item.data.note)?_c('v-chip',{staticClass:"ma-1",attrs:{"color":"grey lighten-3"},on:{"click":function($event){return _vm.showNoteDialog(item)}}},[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v("mdi-information")]),_vm._v(" Note ")],1):_vm._e(),_vm._l((item.data.tags),function(tag,index){return _c('span',{key:'t'+index,staticClass:"ma-1 grey--text"},[_vm._v(" #"+_vm._s(tag)+" ")])})]}},{key:"item.actions",fn:function(ref){
          var item = ref.item;
return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.showUpdateDialog(item)}}},[_c('v-icon',[_vm._v("mdi-wrench")])],1),_c('v-btn',{staticClass:"ml-4",attrs:{"disabled":!item.data.password,"icon":""},on:{"click":function($event){return _vm.toggleShowPassword(item)}}},[_c('v-icon',[_vm._v(_vm._s(_vm.showPassword(item) ? 'mdi-eye' : 'mdi-eye-off'))])],1)]}}])})],1)],1),_c('NoteDialog',{attrs:{"note":_vm.note},model:{value:(_vm.dialogNote),callback:function ($$v) {_vm.dialogNote=$$v},expression:"dialogNote"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }