var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('GenericDialog',{attrs:{"value":_vm.value,"title":"Data","width":800},on:{"input":function($event){return _vm.$emit('input', $event)}},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('v-btn',{attrs:{"color":_vm.jsonMode ? 'grey lighten-5' : 'blue lighten-4',"depressed":""},on:{"click":function($event){_vm.jsonMode = false}}},[_c('v-icon',[_vm._v("mdi-table")])],1),_c('v-btn',{staticClass:"ml-2",attrs:{"color":_vm.jsonMode ? 'blue lighten-4' : 'grey lighten-5',"depressed":""},on:{"click":function($event){_vm.jsonMode = true}}},[_c('v-icon',[_vm._v("mdi-code-json")])],1),_c('br'),_c('br'),(_vm.jsonMode)?_c('v-textarea',{attrs:{"value":_vm.dataBeautiful,"rows":"15","filled":"","hide-details":"","readonly":""}}):_c('v-sheet',{staticClass:"secondary pa-2"},[_c('v-data-table',{attrs:{"headers":[
            { text: 'Field', value: 'field', width: 150 },
            { text: 'Value', value: 'value' },
            { text: 'Action', value: 'action', width: 100 } ],"items":_vm.dataEntries,"items-per-page":-1,"hide-default-footer":"","no-data-text":"No Data"},scopedSlots:_vm._u([{key:"item.field",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getField(item))+" ")]}},{key:"item.value",fn:function(ref){
          var item = ref.item;
return [_c('span',{class:_vm.valueIsEmpty(item) ? ['grey--text'] : [],staticStyle:{"word-wrap":"anywhere"}},[_vm._v(" "+_vm._s(_vm.getValue(item))+" ")])]}},{key:"item.action",fn:function(ref){
          var item = ref.item;
return [_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.canBeDecrypted(item)),expression:"canBeDecrypted(item)"}],attrs:{"icon":""},on:{"click":function($event){return _vm.decrypt(item)}}},[_c('v-icon',[_vm._v("mdi-lock-open")])],1)]}}])})],1)]},proxy:true},{key:"actions",fn:function(){return [_c('v-btn',{attrs:{"color":"black","text":""},on:{"click":function($event){return _vm.$emit('input', false)}}},[_vm._v(" Close ")])]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }