





import {Component, Prop, Ref, Vue} from "vue-property-decorator";

@Component
export default class SearchField extends Vue {

  @Ref('searchField') readonly searchField!: any

  @Prop({required: true})
  readonly value!: string;

  focus() {
    this.searchField.focus();
  }
}
