var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('MainContainer',{attrs:{"title":"Users","icon":"mdi-account-multiple"},scopedSlots:_vm._u([{key:"meta",fn:function(){return [_c('p',{staticClass:"text-h6"},[_vm._v(_vm._s(_vm.users.length)+" "+_vm._s(_vm.users.length === 1 ? 'User' : 'Users'))]),_c('v-btn',{attrs:{"color":"primary","dark":"","block":""},on:{"click":_vm.showCreateUserDialog}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v(" New User ")],1)]},proxy:true}])},[_c('SearchField',{ref:"searchField",model:{value:(_vm.query),callback:function ($$v) {_vm.query=$$v},expression:"query"}}),_c('v-card',{staticClass:"mt-10"},[_c('v-card-text',{staticClass:"pb-0"},[_c('v-data-table',{attrs:{"loading":_vm.fetching,"headers":[
              { text: 'ID', value: 'id' },
              { text: 'Name', value: 'name' },
              { text: 'Admin', value: 'admin' },
              { text: 'Request PW Change', value: 'requestPasswordChange'},
              { text: 'Can Change Name', value: 'canChangeName'},
              { text: 'Version', value: 'version'},
              { text: 'Actions', value: 'actions', sortable: false, width: 140 } ],"items":_vm.usersFiltered,"items-per-page":50,"footer-props":{
             itemsPerPageOptions: [ 20, 50, -1 ]
          },"loading-text":"Loading...","no-data-text":"No Users"},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
          var item = ref.item;
return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.showUpdateNameDialog(item)}}},[_c('v-icon',[_vm._v("mdi-wrench")])],1),_c('v-btn',{staticClass:"ml-4",attrs:{"icon":""},on:{"click":function($event){return _vm.showDeleteUserDialog(item)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}])})],1)],1),_c('CreateUserDialog',{ref:"createUserDialog",attrs:{"loading":_vm.loading},on:{"submit":_vm.createUser},model:{value:(_vm.dialogCreateUser),callback:function ($$v) {_vm.dialogCreateUser=$$v},expression:"dialogCreateUser"}}),_c('SettingNameDialog',{ref:"updateNameDialog",attrs:{"loading":_vm.loading},on:{"submit":_vm.updateUserName},model:{value:(_vm.dialogUpdateName),callback:function ($$v) {_vm.dialogUpdateName=$$v},expression:"dialogUpdateName"}}),_c('DeleteUserDialog',{attrs:{"user-name":_vm.selectedUser ? _vm.selectedUser.name : '',"loading":_vm.loading},on:{"delete":_vm.deleteUser},model:{value:(_vm.dialogDeleteUser),callback:function ($$v) {_vm.dialogDeleteUser=$$v},expression:"dialogDeleteUser"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }