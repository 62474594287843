























import {Component, Prop, Vue} from "vue-property-decorator";
import GenericDialog from "@/components/dialogs/GenericDialog.vue";
import {showSnackbar} from "@/utils";
import {UserLocale} from "@/model";

@Component({
  components: {GenericDialog}
})
export default class SettingLocaleDialog extends Vue {

  @Prop({required: true})
  readonly value!: boolean;

  @Prop({required: true})
  readonly loading!: boolean;

  locales = Object.values(UserLocale);
  locale: UserLocale = UserLocale.EN;

  load(locale: UserLocale) {
    this.locale = locale;
  }

  async submit() {
    if (!this.locale) {
      showSnackbar('Missing Language');
      return;
    }

    await this.$emit('submit', {
      locale: this.locale
    });
  }
}
