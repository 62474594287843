




















import {createCategory, getCategories} from "@/api";
import {Component, Vue} from "vue-property-decorator";
import {showSnackbar} from "@/utils";

@Component
export default class CreateCategory extends Vue {

  loading = false;
  name: string | null = null;

  async createCategory() {

    if (!this.name)
      return;

    this.loading = true;
    try {
      const createdCategory = await createCategory({ name: this.name });
      const categories = await getCategories();
      this.$emit('update-categories', categories, createdCategory.id);
    } catch(status) {
      showSnackbar('Error');
    } finally {
      this.loading = false;
    }
  }
}
