





























import {getIP, login} from "@/api";
import {Component, Ref, Vue} from "vue-property-decorator";
import {showSnackbar} from "@/utils";

@Component
export default class Login extends Vue {

  @Ref('loginName') readonly loginName!: HTMLElement
  @Ref('loginPassword') readonly loginPassword!: HTMLElement

  fetching = true;
  loading = false;
  location: string | null = null;
  ip: string | null = null;
  name: string | null = null;
  password: string | null = null;

  async login() {

    if (this.name && !this.password) {
      this.loginPassword.focus();
      return;
    } else if (!this.name && this.password) {
      this.loginName.focus();
      return;
    } else if (!this.name || !this.password) {
      return;
    }

    this.loading = true;
    try {
      const response = await login({ name: this.name.trim(), password: this.password });
      await this.$emit('update-user-info', response, true);
      await this.$router.push(response.requestPasswordChange ? '/set-password' : '/all');
    } catch(status) {
      console.error(status);
      showSnackbar('Login failed');
    } finally {
      this.loading = false;
    }
  }

  get currYear() {
    return new Date().getFullYear();
  }

  async mounted() {
    this.loginName.focus();
    const response = await getIP();
    this.location = response.location;
    this.ip = response.ip;
    this.fetching = false;
  }
}
