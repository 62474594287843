






























































import {getUsers, createUser, updateUserName, deleteUser} from "@/api";
import {Component, Ref, Vue, Watch} from "vue-property-decorator";
import {showSnackbar} from "@/utils";
import SearchField from "@/components/SearchField.vue";
import {UserDto} from "@/model";
import MainContainer from "@/components/MainContainer.vue";
import CreateUserDialog from "@/components/dialogs/CreateUserDialog.vue";
import SettingNameDialog from "@/components/dialogs/SettingNameDialog.vue";
import DeleteUserDialog from "@/components/dialogs/DeleteUserDialog.vue";

@Component({
  components: {DeleteUserDialog, SearchField, MainContainer, CreateUserDialog, SettingNameDialog}
})
export default class Users extends Vue {

  @Ref('searchField') readonly searchField!: SearchField;
  @Ref('createUserDialog') readonly createUserDialog!: CreateUserDialog;
  @Ref('updateNameDialog') readonly updateNameDialog!: SettingNameDialog;

  fetching = true;
  loading = false;
  users: UserDto[] = [];
  usersFiltered: UserDto[] = []
  query = '';
  selectedUser: UserDto | null = null;

  dialogCreateUser = false;
  dialogUpdateName = false;
  dialogDeleteUser = false;

  async fetchData() {
    try {
      this.fetching = true;
      this.users = await getUsers();
      this.watchQuery(this.query);
    } catch (e) {
      showSnackbar('Error');
    } finally {
      this.fetching = false;
    }
  }

  filter(users: Array<UserDto>, query: string) {
    const queryLowerCase = query.toLowerCase();
    return users.filter(u => u.name.toLowerCase().includes(queryLowerCase));
  }

  @Watch('query')
  watchQuery(value: string) {
    if (value)
      this.usersFiltered = this.filter(this.users, value);
    else
      this.usersFiltered = [ ...this.users ];
  }

  showCreateUserDialog() {
    this.createUserDialog.reset();
    this.dialogCreateUser = true;
  }

  showUpdateNameDialog(user: UserDto) {
    this.selectedUser = user;
    this.updateNameDialog.load(user.name);
    this.dialogUpdateName = true;
  }

  showDeleteUserDialog(user: UserDto) {
    this.selectedUser = user;
    this.dialogDeleteUser = true;
  }

  async createUser(data: any) {
    this.loading = true;
    try {
      await createUser(data);
      this.dialogCreateUser = false;
      await this.fetchData();
      showSnackbar('User created.')
    } catch(status) {
      switch(status) {
        case 409:
          showSnackbar('Name already exists');
          break;
        default:
          showSnackbar('Error');
      }
    } finally {
      this.loading = false;
    }
  }

  async updateUserName(data: any) {
    this.loading = true;
    try {
      await updateUserName({
        id: this.selectedUser!.id,
        ...data,
      });
      this.dialogUpdateName = false;
      await this.fetchData();
      showSnackbar('Name updated.')
    } catch(status) {
      switch(status) {
        case 409:
          showSnackbar('Name already exists');
          break;
        default:
          showSnackbar('Error');
      }
    } finally {
      this.loading = false;
    }
  }

  async deleteUser() {
    try {
      this.loading = true;
      await deleteUser({ userId: this.selectedUser!.id });
      this.dialogDeleteUser = false;
      await this.fetchData();
      showSnackbar('User deleted.')
    } catch (e) {
      showSnackbar('Error');
    } finally {
      this.loading = false;
    }
  }

  async mounted() {
    await this.fetchData();
    this.searchField.focus();
  }
}
