
























import {getUserInfo, updateMasterPasswordSimple} from "@/api";
import {Component, Vue} from "vue-property-decorator";
import {showSnackbar} from "@/utils";

@Component
export default class SetPassword extends Vue {

  loading = false;
  newPassword = '';
  newPasswordRepeat = '';

  async updatePassword() {

    if (!this.newPassword || !this.newPasswordRepeat)
      return;

    if (this.newPassword !== this.newPasswordRepeat) {
      showSnackbar('New passwords do not match.')
      return;
    }

    this.loading = true;
    try {
      await updateMasterPasswordSimple(this.newPassword);
      showSnackbar('Password updated.')
      const userInfo = await getUserInfo();
      this.$emit('update-user-info', userInfo);
      showSnackbar('Password updated.');
      await this.$router.push('/all');
    } catch(status) {
      switch(status) {
        case 403:
          showSnackbar('Wrong old password');
          break;
        case 412:
          showSnackbar('Same Password');
          break;
        default:
          showSnackbar('Error');
      }
    } finally {
      this.loading = false;
    }
  }
}
