export enum UserLocale {
    EN = "EN"
}

export interface UserDto {
    id: number;
    name: string;
    requestPasswordChange: boolean;
    canChangeName: boolean;
    admin: boolean;
    logoutTimeout: number;
    locale: UserLocale;
    trustedIps: string[];
    emailNotice: string | null;
    version: number;
}

export interface IpDto {
    ip: string;
    location: string | null;
}

export interface CategoryDto {
    id: number;
    name: string;
}

export interface CategoryNoId {
    name: string;
}

export interface AccountData {
    name: string;
    userName: string | null;
    email: string | null;
    phone: string | null;
    password: string | null;
    url: string | null;
    note: string | null;
    tags: string[];
}

export interface AccountEncryptedDto {
    id: number;
    category: CategoryDto;
    data: string;
}

export interface AccountDto {
    id: number;
    category: CategoryDto;
    data: AccountData;
}

export interface AccountNoId {
    categoryId: number;
    data: AccountData;
}

export interface AccountsOfCategoryDto {
    category: CategoryDto;
    accounts: Array<AccountDto>;
}

export interface ValueCount {
    val: string;
    count: number;
}

export enum LogType {
    LOGIN = "LOGIN",
    CREATE_CATEGORY = "CREATE_CATEGORY", UPDATE_CATEGORY = "UPDATE_CATEGORY", DELETE_CATEGORY = "DELETE_CATEGORY",
    CREATE_ACCOUNT = "CREATE_ACCOUNT", UPDATE_ACCOUNT = "UPDATE_ACCOUNT", DELETE_ACCOUNT = "DELETE_ACCOUNT",
    UPDATE_MASTER_NAME = "UPDATE_MASTER_NAME", UPDATE_MASTER_PASSWORD = "UPDATE_MASTER_PASSWORD",
    UPDATE_MASTER_LOGOUT_TIMEOUT = "UPDATE_MASTER_LOGOUT_TIMEOUT", UPDATE_MASTER_LOCALE = "UPDATE_MASTER_LOCALE", UPDATE_MASTER_TRUSTED_IPS = "UPDATE_MASTER_TRUSTED_IPS", UPDATE_MASTER_EMAIL = "UPDATE_MASTER_EMAIL"
}

export interface LogDto {
    id: number;
    timestamp: string;
    type: LogType;
    data: object[];
    ip: string | null;
    isp: string | null;
    country: string | null;
    region: string | null;
    city: string | null;
    lat: number | null;
    lon: number | null;
    os: string | null;
    browser: string | null;
}