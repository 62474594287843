








































































import {Component, Prop, Vue} from "vue-property-decorator";
import {showSnackbar} from "@/utils";
import NoteDialog from "@/components/dialogs/NoteDialog.vue";
import {AccountDto} from "@/model";
@Component({
  components: {NoteDialog}
})
export default class AccountTable extends Vue {

  @Prop({required: true})
  readonly accounts!: AccountDto[];

  @Prop({required: true})
  readonly fetching!: boolean;

  @Prop({default: false})
  readonly categoryColumn!: boolean;

  showPasswords: Set<number> = new Set<number>(); // set of account ids
  dialogNote = false;
  note: string | null = '';

  showNoteDialog(account: AccountDto) {
    this.note = account.data.note;
    this.dialogNote = true;
  }

  showUpdateDialog(account: AccountDto) {
    this.$emit('update', account);
  }

  toggleShowPassword(account: AccountDto) {
    if (this.showPasswords.has(account.id)) {
      // remove from list
      this.showPasswords.delete(account.id);
    } else {
      // add to list
      this.showPasswords.add(account.id);
    }

    // so Vue can pickup this change
    this.showPasswords = new Set(this.showPasswords);
  }

  copy(text: string, message: string) {
    navigator.clipboard.writeText(text);
    showSnackbar(message);
  }

  get showPassword() {
    return (account: AccountDto) => this.showPasswords.has(account.id);
  }
}
