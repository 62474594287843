





























import {Component, Prop, Vue} from "vue-property-decorator";
import GenericDialog from "@/components/dialogs/GenericDialog.vue";
import {trimmedOrNull} from "@/utils";
import { sendTestEmail } from '@/api';

@Component({
  components: {GenericDialog}
})
export default class SettingEmailDialog extends Vue {

  @Prop({required: true})
  readonly value!: boolean;

  @Prop({required: true})
  readonly loading!: boolean;

  email = '';
  sentEmail = '';

  load(email: string) {
    this.email = email;
  }

  async submit() {
    await this.$emit('submit', {
      emailNotice: trimmedOrNull(this.email)
    });
  }

  async sendTestEmail() {
    await sendTestEmail(this.email);
    this.sentEmail = this.email;
  }
}
