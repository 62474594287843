







































































































import {logout} from "@/api";
import moment from "moment";
import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import {CategoryDto, UserDto} from "@/model";

@Component
export default class Navigation extends Vue {

  @Prop({required: true})
  readonly userInfo: UserDto | null = null;

  @Prop({required: true})
  readonly seconds!: number;

  @Prop({required: true})
  readonly categories!: Array<CategoryDto>;

  drawerVisible = false;
  postPages = [
    {
      route: '/category/new',
      label: 'New Category',
      icon: 'mdi-tag-plus'
    },
    {
      route: '/settings',
      label: 'Settings',
      icon: 'mdi-cog'
    },
    {
      route: '/logs',
      label: 'Logs',
      icon: 'mdi-history'
    }
  ];

  adminPages = [
    {
      route: '/users',
      label: 'Users',
      icon: 'mdi-account-multiple'
    }
  ];

  async logout() {
    logout();
    this.$emit('update-user-info');
    await this.$router.push('/');
    console.log('[' + moment().format('HH:mm:ss') + '] Logout (by user)');
  }

  get timeout() {
    const minutes = Math.floor(this.seconds / 60);
    let seconds: number | string = this.seconds % 60;
    if (seconds < 10)
      seconds = '0' + seconds;

    return minutes+':'+seconds;
  }

  get secondsInDanger() {
    return this.seconds <= 10;
  }

  @Watch('loggedIn', { immediate: true })
  onPropertyChanged(value: boolean) {
    this.drawerVisible = value;
  }
}
