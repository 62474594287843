



















































import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import GenericDialog from "@/components/dialogs/GenericDialog.vue";
import {decryptCurrentKey} from "@/api";
import {showSnackbar} from "@/utils";

type DataEntry = {
  field: string;
  value: any;
};

@Component({
  components: {GenericDialog}
})
export default class LogDataDialog extends Vue {

  @Prop({required: true})
  readonly value!: boolean;

  @Prop({required: true})
  readonly data!: object;

  decrypted: Record<string, string> = {};
  jsonMode = false;

  get dataBeautiful(): string {
    return JSON.stringify(this.data, null, 4);
  }

  get dataEntries(): DataEntry[] {
    const entries: DataEntry[] = [];
    for (const [field, value] of Object.entries(this.data)) {
      entries.push({field, value});
    }
    return entries;
  }

  getField(entry: DataEntry): string {
    switch (entry.field) {
      // account
      case 'id': return 'ID';
      case 'name': return 'Name';
      case 'categoryId': return 'Category ID';
      case 'userName': return 'User Name';
      case 'email': return 'Email';
      case 'phone': return 'Phone Number';
      case 'password': return 'Password';
      case 'loginWith': return 'Login-With ID';
      case 'url': return 'URL';
      case 'note': return 'Note';
      case 'tags': return 'Tags';

      // category
      case 'userId': return 'User ID';
      case 'icon': return 'Icon';
      default: return entry.field;
    }
  }

  getValue(entry: DataEntry): any {
    return this.decrypted[entry.field] || entry.value?.toString() || '(empty)';
  }

  valueIsEmpty(entry: DataEntry): boolean {
    return !entry.value // value is null
        || (Array.isArray(entry.value) && entry.value.length === 0) // array is empty
  }

  canBeDecrypted(entry: DataEntry): boolean {
    return entry.value // is not null
        && isNaN(entry.value) // is not a number
        && !(entry.field in this.decrypted); // is not decrypted
  }

  decrypt(entry: DataEntry) {
    try {

      let decrypted: string;
      if (Array.isArray(entry.value)) {
        // decrypt array and join them
        decrypted = entry.value.map(item => decryptCurrentKey(item)).join(', ');
      } else {
        // decrypt single string
        decrypted = decryptCurrentKey(entry.value);
      }

      this.decrypted = {
        [entry.field]: decrypted,
        ...this.decrypted
      };
    } catch (e) {
      console.error(e);
      showSnackbar('Error');
    }
  }

  @Watch('value')
  async watchQuery(value: boolean) {
    if (value) {
      this.decrypted = {}; // reset
    }
  }
}
