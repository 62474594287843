



































import {Component, Prop, Vue} from "vue-property-decorator";
import GenericDialog from "@/components/dialogs/GenericDialog.vue";

@Component({
  components: {GenericDialog}
})
export default class SettingIpDialog extends Vue {

  @Prop({required: true})
  readonly value!: boolean;

  @Prop({required: true})
  readonly loading!: boolean;

  ips: string[] = [];
  ipInput = '';
  valid = false;

  load(ips: string[]) {
    this.ips = [ ...ips ];
    this.ipInput = '';
  }

  async submit() {
    if (this.ipInput) {
      // user may forgot to press enter
      this.addIp();
    }
    await this.$emit('submit', {
      trustedIps: this.ips
    });
  }

  addIp() {
    if (!this.ipInput.trim() || !this.valid)
      return;
    this.ips.push(this.ipInput.trim());
    this.ipInput = '';
  }

  deleteTag(ip: string) {
    this.ips = this.ips.filter(i => i !== ip);
  }

  get ruleNotLocal() {
    return (value: string) => !(value && (value.trim() === '127.0.0.1' || value.trim() === '0:0:0:0:0:0:0:1')) || 'IP address white-listed by default';
  }

  // use getter function instead of rules array, because we access state data
  get ruleNotExist() {
    return (value: string) => !(value && this.ips.includes(value.trim())) || 'IP address already exist'
  }
}
