var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('MainContainer',{attrs:{"title":"Logs","icon":"mdi-history"}},[_c('SearchField',{ref:"searchField",model:{value:(_vm.query),callback:function ($$v) {_vm.query=$$v},expression:"query"}}),_c('v-card',{staticClass:"mt-10"},[_c('v-card-text',{staticClass:"pb-0"},[_c('v-data-table',{attrs:{"loading":_vm.fetching,"headers":[
              { text: 'Timestamp', value: 'timestamp', width: 160 },
              { text: 'Action', value: 'type' },
              { text: 'Data', value: 'data', width: 130 },
              { text: 'OS', value: 'os', width: 150 },
              { text: 'Browser', value: 'browser' },
              { text: 'IP', value: 'ip' },
              { text: 'Geolocation', value: 'iplocation' }
          ],"items":_vm.logsFiltered,"items-per-page":50,"footer-props":{
             itemsPerPageOptions: [ 20, 50, -1 ]
          },"loading-text":"Loading...","no-data-text":"No Logs"},scopedSlots:_vm._u([{key:"item.timestamp",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.timestamp(item.timestamp))+" ")]}},{key:"item.type",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.typeToString(item.type))+" ")]}},{key:"item.data",fn:function(ref){
          var item = ref.item;
return [_vm._l((item.data),function(d,index){return [_c('v-btn',{key:'btn-'+index,attrs:{"icon":""},on:{"click":function($event){return _vm.showData(d)}}},[_c('v-icon',[_vm._v("mdi-code-json")])],1),(index !== item.data.length - 1)?_c('v-icon',{key:'separator'-index},[_vm._v("mdi-chevron-right")]):_vm._e()]})]}},{key:"item.ip",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(item.ip)+" "),(_vm.trusted(item.ip))?_c('v-icon',{attrs:{"color":"green","right":"","small":""}},[_vm._v(" mdi-check-circle ")]):_vm._e()]}},{key:"item.iplocation",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(item.city)+" ")]}}])})],1)],1),_c('LogDataDialog',{attrs:{"data":_vm.selectedData},model:{value:(_vm.dialogLogData),callback:function ($$v) {_vm.dialogLogData=$$v},expression:"dialogLogData"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }