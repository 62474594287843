






























































































import {Component, Prop, Ref, Vue, Watch} from "vue-property-decorator";
import GenericDialog from "@/components/dialogs/GenericDialog.vue";
import {showSnackbar, trimmedOrNull} from "@/utils";
import {generatePassword} from "@/crypto";
import {AccountDto, CategoryDto, ValueCount} from "@/model";

enum AccountField {
  USER_NAME = "USER_NAME",
  EMAIL = "EMAIL",
  PHONE = "PHONE",
  PASSWORD = "PASSWORD",
  URL = "URL",
  NOTE = "NOTE",
  TAGS = "TAGS"
}

@Component({
  components: {GenericDialog}
})
export default class AccountDialog extends Vue {

  @Ref('emailInput') readonly emailInput!: any
  @Ref('phoneInput') readonly phoneInput!: any

  @Prop({required: true})
  readonly value!: boolean;

  @Prop({required: true})
  readonly updating!: boolean;

  @Prop({required: true})
  readonly loading!: boolean;

  @Prop({required: true})
  readonly categories!: Array<CategoryDto>;

  @Prop({required: true})
  readonly emailList!: Array<ValueCount>;

  @Prop({required: true})
  readonly phoneList!: Array<ValueCount>;

  AccountField = AccountField; // make enum accessible in template

  name = '';
  userName = '';
  email: string | ValueCount = '';
  phone: string | ValueCount = '';
  password = '';
  url = '';
  note = '';
  tagInput = '';
  tags: string[] = [];
  category: CategoryDto | null = null;

  // visuals
  possibleFields: AccountField[] = [];
  visibleFields: AccountField[] = [];
  showPassword = false;

  reset(category: CategoryDto) {
    this.name = '';
    this.userName = '';
    this.email = '';
    this.phone = '';
    this.password = '';
    this.url = '';
    this.note = '';
    this.tagInput = '';
    this.tags = [];
    this.category = category;

    this.visibleFields = [];
    this.showPassword = false;
  }

  load(account: AccountDto) {
    this.name = account.data.name;
    this.userName = account.data.userName || '';
    this.email = account.data.email || '';
    this.phone = account.data.phone || '';
    this.password = account.data.password || '';
    this.url = account.data.url || '';
    this.note = account.data.note || '';
    this.tags = account.data.tags;
    this.category = this.categories.find((c: CategoryDto) => c.id === account.category.id) || null;

    if (this.userName)
      this.visibleFields.push(AccountField.USER_NAME);

    if (this.email)
      this.visibleFields.push(AccountField.EMAIL);

    if (this.phone)
      this.visibleFields.push(AccountField.PHONE);

    if (this.password)
      this.visibleFields.push(AccountField.PASSWORD);

    if (this.url)
      this.visibleFields.push(AccountField.URL);

    if (this.note)
      this.visibleFields.push(AccountField.NOTE);

    if (this.tags.length !== 0)
      this.visibleFields.push(AccountField.TAGS);
  }

  submit() {
    // https://github.com/vuetifyjs/vuetify/issues/3424
    this.emailInput.blur();
    this.phoneInput.blur();
    this.$nextTick(() => {
      this.actualSubmit();
    });
  }

  actualSubmit() {
    if (!this.name) {
      showSnackbar('Empty Name');
      return;
    }

    if (!this.category) {
      showSnackbar('Missing Category');
      return;
    }

    const emailValue = (this.email as ValueCount).val || this.email;
    const phoneValue = (this.phone as ValueCount).val || this.phone;

    if (this.tagInput) {
      // user may forgot to press enter
      this.addTag();
    }

    this.$emit('submit', {
      category: this.category,
      categoryId: this.category.id,
      data: {
        name: this.name,
        userName: trimmedOrNull(this.userName),
        email: trimmedOrNull(emailValue as string),
        phone: trimmedOrNull(phoneValue as string),
        password: trimmedOrNull(this.password),
        url: trimmedOrNull(this.url),
        note: trimmedOrNull(this.note),
        tags: this.tags
      }
    });
  }

  addField(field: AccountField) {
    this.visibleFields.push(field);
  }

  addTag() {
    if (!this.tagInput.trim())
      return;
    const trimmed = this.tagInput.trim();
    if (this.tags.some(t => t === trimmed))
      return; // already exists
    this.tags.push(trimmed);
    this.tagInput = '';
  }

  deleteTag(tag: string) {
    this.tags = this.tags.filter(t => t !== tag);
  }

  generatePassword() {
    this.password = generatePassword();
  }

  fieldToString(field: AccountField): string {
    return {
      "USER_NAME": 'User Name',
      "EMAIL": 'Email',
      "PHONE": 'Phone',
      "PASSWORD": 'Password',
      "URL": "Link",
      "NOTE": 'Note',
      "TAGS": 'Tags'
    }[field];
  }

  get dialogTitle() {
    return this.updating ? 'Edit Account' : 'New Account';
  }

  get submitText() {
    return this.updating ? 'Save' : 'Create';
  }

  get submitIcon() {
    return this.updating ? 'mdi-content-save' : 'mdi-plus';
  }

  @Watch('visibleFields')
  async watchQuery(visibleFields: AccountField[]) {
    this.possibleFields = Object.values(AccountField).filter(field => !visibleFields.includes(field));
  }
}
