


















import {Component, Prop, Vue} from "vue-property-decorator";
import GenericDialog from "@/components/dialogs/GenericDialog.vue";
import {showSnackbar} from "@/utils";

@Component({
  components: {GenericDialog}
})
export default class SettingNameDialog extends Vue {

  @Prop({required: true})
  readonly value!: boolean;

  @Prop({required: true})
  readonly loading!: boolean;

  name = '';

  load(name: string) {
    this.name = name;
  }

  async submit() {
    if (!this.name) {
      showSnackbar('Missing Name');
      return;
    }

    await this.$emit('submit', {
      name: this.name
    });
  }
}
