














import {Component, Prop, Vue} from "vue-property-decorator";
import GenericDialog from "@/components/dialogs/GenericDialog.vue";

@Component({
  components: {GenericDialog}
})
export default class NoteDialog extends Vue {

  @Prop({required: true})
  readonly value!: boolean;

  @Prop({required: false, default: ''})
  readonly note!: string;
}
