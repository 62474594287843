







































import {
  createAccount, deleteAccount,
  deleteCategory,
  getAccounts,
  getCategories,
  updateAccount,
  updateCategory
} from "@/api";

import {Component, Prop, Ref, Vue, Watch} from "vue-property-decorator";
import {getValueCounts, showSnackbar} from "@/utils";
import CategoryDialog from "@/components/dialogs/CategoryDialog.vue";
import AccountDialog from "@/components/dialogs/AccountDialog.vue";
import MainContainer from "@/components/MainContainer.vue";
import AccountTable from "@/components/AccountTable.vue";
import SearchField from "@/components/SearchField.vue";
import DeleteAccountDialog from "@/components/dialogs/DeleteAccountDialog.vue";
import DeleteCategoryDialog from "@/components/dialogs/DeleteCategoryDialog.vue";
import {AccountDto, AccountNoId, CategoryDto, CategoryNoId, ValueCount} from "@/model";

@Component({
  components: {
    DeleteCategoryDialog,
    DeleteAccountDialog,
    SearchField, AccountTable, AccountDialog, CategoryDialog, MainContainer}
})
export default class CategoryAccounts extends Vue {

  @Ref('searchField') readonly searchField!: SearchField;
  @Ref('categoryDialog') readonly categoryDialog!: CategoryDialog;
  @Ref('accountDialog') readonly accountDialog!: AccountDialog;

  @Prop({required: true})
  readonly categories!: Array<CategoryDto>;

  fetching = true;
  loading = false;
  category: CategoryDto = { id: -1, name: '' };
  accounts: Array<AccountDto> = [];
  emailList: Array<ValueCount> = [];
  phoneList: Array<ValueCount> = [];
  accountsFiltered: Array<AccountDto> = [];
  query = '';
  dialogAccount = false;
  dialogCategory = false;
  dialogUpdating = false;
  dialogDeleteAccount = false;
  dialogDeleteCategory = false;
  selectedAccount: AccountDto = { category: { id: -1, name: '' }, id: 0, data: { name: '', userName: null, email: null, phone: null, password: null, url: null, note: null, tags: [] } };

  async fetchData() {
    try {
      this.fetching = true;
      const categoryId = this.$route.params.categoryId;
      const response = await getAccounts({ categoryId: parseInt(categoryId) });
      this.category = response.category;
      this.accounts = response.accounts;
      this.emailList = getValueCounts(this.accounts, a => a.data.email);
      this.phoneList = getValueCounts(this.accounts, a => a.data.phone);
      this.accountsFiltered = this.filter(this.accounts, this.query);
    } catch (e) {
      showSnackbar('Error');
    } finally {
      this.fetching = false;
    }
  }

  filter(accounts: Array<AccountDto>, query: string) {
    const queryLowerCase = query.toLowerCase();
    return accounts.filter(a => a.data.name.toLowerCase().includes(queryLowerCase)
        || a.data.email?.toLowerCase()?.includes(queryLowerCase)
        || a.data.phone?.toLowerCase()?.includes(queryLowerCase)
        || a.data.tags.some(t => t.toLowerCase().includes(queryLowerCase)));
  }

  showUpdateAccountDialog(account: AccountDto) {
    this.selectedAccount = account;
    this.accountDialog.reset(this.category);
    this.accountDialog.load(account);
    this.dialogUpdating = true;
    this.dialogAccount = true;
  }

  showDeleteAccountDialog() {
    this.dialogAccount = false;
    this.dialogDeleteAccount = true;
  }

  showUpdateCategoryDialog() {
    this.categoryDialog.reset();
    this.categoryDialog.load(this.category);
    this.dialogCategory = true;
  }

  showDeleteCategoryDialog() {
    this.dialogCategory = false;
    this.dialogDeleteCategory = true;
  }

  showCreateAccountDialog() {
    this.accountDialog.reset(this.category);
    this.dialogUpdating = false;
    this.dialogAccount = true;
  }

  async updateCategory(category: CategoryNoId) {
    try {
      this.loading = true;
      await updateCategory({
        id: this.category.id,
        ...category
      });
      this.dialogCategory = false;
      const categories = await getCategories();
      this.category.name = category.name;
      this.$emit('update-categories', categories, this.category.id);
    } catch (e) {
      showSnackbar('Error');
    } finally {
      this.loading = false;
    }
  }

  async deleteCategory() {
    try {
      this.loading = true;
      await deleteCategory({ categoryId: this.category.id });
      const categories = await getCategories();
      this.$emit('update-categories', categories);
    } catch (e) {
      showSnackbar('Error');
    } finally {
      this.loading = false;
    }
  }

  async submitAccount(account: AccountNoId) {
    if (this.dialogUpdating)
      await this.updateAccount(account);
    else
      await this.createAccount(account);
  }

  async createAccount(account: AccountNoId) {
    try {
      this.loading = true;
      await createAccount(account);
      this.dialogAccount = false;
      await this.fetchData();
    } catch (e) {
      console.error(e);
      showSnackbar('Error');
    } finally {
      this.loading = false;
    }
  }

  async updateAccount(account: AccountNoId) {
    try {
      this.loading = true;
      await updateAccount({
        id: this.selectedAccount.id,
        ...account
      });
      this.dialogAccount = false;
      await this.fetchData();
    } catch (e) {
      showSnackbar('Error');
    } finally {
      this.loading = false;
    }
  }

  async deleteAccount() {
    try {
      this.loading = true;
      await deleteAccount({ accountId: this.selectedAccount.id });
      this.dialogDeleteAccount = false;
      await this.fetchData();
    } catch (e) {
      showSnackbar('Error');
    } finally {
      this.loading = false;
    }
  }

  async mounted() {
    await this.fetchData();
    this.searchField.focus();
  }

  @Watch('$route')
  async watchRoute() {
    this.query = '';
    await this.fetchData();
    this.searchField.focus();
  }

  @Watch('query')
  async watchQuery(value: string) {
    this.accountsFiltered = this.filter(this.accounts, value);
  }

}
