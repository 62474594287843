import {AccountDto, ValueCount} from "@/model";

let snackbarFunc: Function;

export function initSnackbar(func: Function) {
    snackbarFunc = func;
}

export function showSnackbar(text: string) {
    snackbarFunc(text);
}

export function sleep(millis: number) {
    return new Promise(resolve => setTimeout(resolve, millis));
}

export function getValueCounts(accounts: Array<AccountDto>, selector: (a: AccountDto) => string | null): Array<ValueCount> {

    const map: Record<string, number> = {};
    accounts.forEach((a: AccountDto) => {
        const value = selector(a);
        if (value) {
            if (map[value]) {
                map[value]++;
            } else {
                map[value] = 1;
            }
        }
    });

    // convert map to list
    const list: Array<ValueCount> = [];
    for (const key of Object.keys(map)) {
        list.push({
            val: key,
            count: map[key]
        })
    }

    return list.sort((a: ValueCount, b: ValueCount) => b.count - a.count); // reversed order (high count to low count)
}

export function trimmedOrNull(input: string): string | null {
    const trimmed = input.trim();
    if (trimmed)
        return trimmed;
    else
        return null;
}