





















import {Component, Prop, Vue} from "vue-property-decorator";
import GenericDialog from "@/components/dialogs/GenericDialog.vue";

@Component({
  components: {GenericDialog}
})
export default class SettingTimeoutDialog extends Vue {

  @Prop({required: true})
  readonly value!: boolean;

  @Prop({required: true})
  readonly loading!: boolean;

  rules = {
    required: (value: string) => !!value || 'Required.',
    noComma: (value: string) => !value.includes(',') && !value.includes('.') || 'No commas.',
    mustBeNumber: (value: string) => !isNaN(+value) || 'It must be a number.',
    positive: (value: string) => Number(value) >= 30 || 'Must be 30 or larger.'
  };

  timeout = '';
  valid = false;

  load(timeout: number) {
    this.timeout = timeout.toString();
  }

  async submit() {
    if (!this.valid) {
      return;
    }

    await this.$emit('submit', {
      logoutTimeout: Number(this.timeout)
    });
  }
}
