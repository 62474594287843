



























































import {getLogs} from "@/api";
import {Component, Prop, Ref, Vue, Watch} from "vue-property-decorator";
import {showSnackbar} from "@/utils";
import moment from "moment";
import MainContainer from "../components/MainContainer.vue";
import LogDataDialog from "@/components/dialogs/LogDataDialog.vue";
import SearchField from "@/components/SearchField.vue";
import {LogDto, LogType, UserDto} from "@/model";

@Component({
  components: {SearchField, LogDataDialog, MainContainer}
})
export default class Logs extends Vue {

  @Ref('searchField') readonly searchField!: SearchField;

  @Prop({required: true})
  readonly userInfo!: UserDto;

  fetching = true;
  logs: LogDto[] = [];
  logsFiltered: LogDto[] = []
  query = '';

  dialogLogData = false;
  selectedData: object = {};

  async fetchData() {
    try {
      this.fetching = true;
      this.logs = await getLogs();
      if (this.query)
        this.logsFiltered = this.filter(this.logs, this.query);
      else
        this.logsFiltered = [ ...this.logs ];
    } catch (e) {
      showSnackbar('Error');
    } finally {
      this.fetching = false;
    }
  }

  filter(logs: Array<LogDto>, query: string) {
    const queryLowerCase = query.toLowerCase();
    return logs.filter(l => this.timestamp(l.timestamp).toLowerCase().includes(queryLowerCase)
        || this.typeToString(l.type).toLowerCase()?.includes(queryLowerCase)
        || l.os?.toLowerCase().includes(queryLowerCase)
        || l.browser?.toLowerCase().includes(queryLowerCase)
        || l.ip?.toLowerCase()?.includes(queryLowerCase)
        || l.city?.toLowerCase().includes(queryLowerCase));
  }

  showData(data: string) {
    this.selectedData = JSON.parse(data);
    this.dialogLogData = true;
  }

  typeToString(field: LogType): string {
    switch (field) {
      case "LOGIN": return "Login";
      case "CREATE_CATEGORY": return "Create Category";
      case "UPDATE_CATEGORY": return "Update Category";
      case "DELETE_CATEGORY": return "Delete Category";
      case "CREATE_ACCOUNT": return "Create Account";
      case "UPDATE_ACCOUNT": return "Update Account";
      case "DELETE_ACCOUNT": return "Delete Account";
      case "UPDATE_MASTER_NAME": return "Master: New Name";
      case "UPDATE_MASTER_PASSWORD": return "Master: New Password";
      case "UPDATE_MASTER_LOGOUT_TIMEOUT": return "Master: Timeout";
      case "UPDATE_MASTER_LOCALE": return "Master: Language";
      case "UPDATE_MASTER_TRUSTED_IPS": return "Master: Trusted IPs";
      case "UPDATE_MASTER_EMAIL": return "Master: Email";
      default: return field;
    }
  }

  get timestamp() {
    return (t: string) => moment(t).format('DD.MM.YYYY, HH:mm');
  }

  get trusted() {
    return (ip: string) => ip === '127.0.0.1' || ip === '0:0:0:0:0:0:0:1' || this.userInfo.trustedIps.includes(ip);
  }

  @Watch('query')
  async watchQuery(value: string) {
    if (value)
      this.logsFiltered = this.filter(this.logs, value);
    else
      this.logsFiltered = [ ...this.logs ];
  }

  async mounted() {
    await this.fetchData();
    this.searchField.focus();
  }
}
