


































































import {
  getUserInfo,
  updateMasterEmailNotice, updateMasterLocale, updateMasterLogoutTimeout,
  updateMasterName,
  updateMasterPassword, updateMasterTrustedIps
} from "@/api";
import {Component, Prop, Ref, Vue} from "vue-property-decorator";
import {showSnackbar} from "@/utils";
import MainContainer from "@/components/MainContainer.vue";
import SettingNameDialog from "@/components/dialogs/SettingNameDialog.vue";
import {UserDto} from "@/model";
import SettingSection from "@/components/SettingSection.vue";
import SettingPasswordDialog from "@/components/dialogs/SettingPasswordDialog.vue";
import SettingEmailDialog from "@/components/dialogs/SettingEmailDialog.vue";
import SettingIpDialog from "@/components/dialogs/SettingIpDialog.vue";
import SettingLocaleDialog from "@/components/dialogs/SettingLocaleDialog.vue";
import SettingTimeoutDialog from "@/components/dialogs/SettingTimeoutDialog.vue";
@Component({
  components: {
    SettingTimeoutDialog,
    SettingLocaleDialog,
    SettingIpDialog,
    SettingEmailDialog, SettingPasswordDialog, SettingSection, SettingNameDialog, MainContainer}
})
export default class Settings extends Vue {

  @Ref('nameDialog') readonly nameDialog!: SettingNameDialog;
  @Ref('passwordDialog') readonly passwordDialog!: SettingPasswordDialog;
  @Ref('emailDialog') readonly emailDialog!: SettingEmailDialog;
  @Ref('ipDialog') readonly ipDialog!: SettingIpDialog;
  @Ref('localeDialog') readonly localeDialog!: SettingLocaleDialog;
  @Ref('timeoutDialog') readonly timeoutDialog!: SettingTimeoutDialog;

  @Prop({required: true})
  readonly userInfo!: UserDto;

  fetching = true;
  loading = false;

  dialogName = false;
  dialogPassword = false;
  dialogEmail = false;
  dialogIps = false;
  dialogLocale = false;
  dialogTimeout = false;

  async fetchData() {
    try {
      this.fetching = true;
      const response = await getUserInfo();
      this.$emit('update-user-info', response);
    } catch (e) {
      showSnackbar('Error');
    } finally {
      this.fetching = false;
    }
  }

  showNameDialog() {
    this.nameDialog.load(this.userInfo.name);
    this.dialogName = true;
  }

  async updateName(data: any) {
    this.loading = true;
    try {
      await updateMasterName(data);
      this.dialogName = false;
      await this.fetchData();
      showSnackbar('Name updated.')
    } catch(status) {
      switch(status) {
        case 409:
          showSnackbar('Name already exists');
          break;
        case 412:
          showSnackbar('Same Name');
          break;
        default:
          showSnackbar('Error');
      }
    } finally {
      this.loading = false;
    }
  }

  showPasswordDialog() {
    this.passwordDialog.reset();
    this.dialogPassword = true;
  }

  async updatePassword(data: any) {
    this.loading = true;
    try {
      await updateMasterPassword(data);
      this.dialogPassword = false;
      showSnackbar('Password updated.');
    } catch(status) {
      switch(status) {
        case 403:
          showSnackbar('Wrong old password');
          break;
        case 412:
          showSnackbar('Same Password');
          break;
        default:
          showSnackbar('Error');
      }
    } finally {
      this.loading = false;
    }
  }

  showEmailDialog() {
    this.emailDialog.load(this.userInfo.emailNotice || '');
    this.dialogEmail = true;
  }

  async updateEmailNotice(data: any) {
    this.loading = true;
    try {
      await updateMasterEmailNotice(data);
      this.dialogEmail = false;
      await this.fetchData();
      showSnackbar('Email updated.')
    } catch (status) {
      showSnackbar('Error');
    } finally {
      this.loading = false;
    }
  }

  showIpDialog() {
    this.ipDialog.load(this.userInfo.trustedIps);
    this.dialogIps = true;
  }

  async updateIps(data: any) {
    this.loading = true;
    try {
      await updateMasterTrustedIps(data);
      this.dialogIps = false;
      await this.fetchData();
      showSnackbar('IPs updated.')
    } catch (status) {
      showSnackbar('Error');
    } finally {
      this.loading = false;
    }
  }

  showLocaleDialog() {
    this.localeDialog.load(this.userInfo.locale);
    this.dialogLocale = true;
  }

  async updateLocale(data: any) {
    this.loading = true;
    try {
      await updateMasterLocale(data);
      this.dialogLocale = false;
      await this.fetchData();
      showSnackbar('Language updated.')
    } catch (status) {
      showSnackbar('Error');
    } finally {
      this.loading = false;
    }
  }

  showTimeoutDialog() {
    this.timeoutDialog.load(this.userInfo.logoutTimeout);
    this.dialogTimeout = true;
  }

  async updateTimeout(data: any) {
    this.loading = true;
    try {
      await updateMasterLogoutTimeout(data);
      this.dialogTimeout = false;
      await this.fetchData();
      showSnackbar('Timeout updated.')
    } catch (status) {
      showSnackbar('Error');
    } finally {
      this.loading = false;
    }
  }

  async mounted() {
    await this.fetchData();
  }
}
