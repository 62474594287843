





































import {Component, Prop, Vue} from "vue-property-decorator";

@Component
export default class MainContainer extends Vue {

  @Prop({required: true})
  readonly title!: string;

  @Prop({required: true})
  readonly icon!: string;

  @Prop({required: false, default: 1200})
  readonly maxWidth!: number;

  @Prop({required: false, default: true})
  readonly topSpacer!: boolean;
}
